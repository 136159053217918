input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: rgb(98, 113, 121);
}

.App {
    text-align: center;
    background-image: url("static/background_04.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
    width: 100%;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-float infinite 3s ease-in-out;
    }
}

.container {
    /*background: #21457a;*/
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0px);
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
    border-collapse: collapse;
    /*max-width: 900px;*/
    width: 100%;
    /*margin: 2rem auto;*/
}

sup {
    font-size: 50%;
}

.datagrid-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
}

.tipificacao-table {
    border-collapse: collapse;
    width: 900px !important;
    margin: 0;
}

table th, table td {
    padding: 2px;
    line-height: 1.5;
    border-bottom: 0 !important;
}

table td {
    border: none;
    border-bottom: 0 !important;
}

svg {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.login-box {
    box-shadow: 0 0 20px;
    border-radius: 20px;
    /*margin-top: 8px;*/
    margin: 16px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 32px;
    background-color: white;
}

.description-box {
    box-shadow: 0 0 20px;
    border-radius: 20px;
    /*margin-top: 8px;*/
    margin: 16px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /*padding: 48px 32px;*/
    background-color: white;
}

.subscription-box {
    box-shadow: 0 0 20px;
    border-radius: 20px;
    /*margin-top: 8px;*/
    margin: 16px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*padding: 48px 32px;*/
    background-color: white;
}

.register-box {
    box-shadow: 0 0 20px;
    border-radius: 20px;
    margin: 16px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 32px;
    background-color: white;
}

.MuiTypography-root {
    color: rgb(98, 113, 121);
}

.color-grey {
    color: rgb(98, 113, 121) !important;
}

.MuiFormLabel-root {
    color: rgb(98, 113, 121);
}

.MuiFormLabel-root .MuiInputLabel-root .Mui-focused {
    color: rgb(98, 113, 121);
}

.MuiInputLabel-root .Mui-focused {
    color: white;
}

.select:before {
    border-bottom: 2px solid white !important; /* Importante para sobrepor o estilo padrão */
}

.select:hover:before {
    border-bottom: 2px solid #ff7e01 !important; /* Importante para sobrepor o estilo padrão */
}

.select:after {
    border-bottom: 2px solid white !important; /* Importante para sobrepor o estilo padrão */
}

.select:hover:after {
    border-bottom: 2px solid #ff7e01 !important; /* Importante para sobrepor o estilo padrão */
}

/*.select .MuiFormLabel-root {*/
/*    color: rgb(98, 113, 121);*/
/*}*/

.match-dropdown .MuiFormLabel-root,
.match-dropdown .MuiFormLabel-root .Mui-focused,
.match-dropdown .MuiFormLabel-root .MuiInput-underline,
.match-dropdown .MuiFormLabel-root .Mui-selected,
.championship-dropdown .MuiFormLabel-root,
.championship-dropdown .MuiSvgIcon-root {
    color: white !important;
}


.championship-dropdown .MuiSvgIcon-root:hover {
    color: #ff7e01 !important;
}

.championship-add-button {
    text-align: left;
}

.select .MuiSelect-select {
    color: white !important;
    /*margin: 10px;*/
}

.register {

}

.register .select .MuiSelect-select {
    color: rgb(98, 113, 121) !important;
    /*margin: 10px;*/
}

.MuiButton-root:hover,
.MuiButton-root:focus,
.MuiButton-root:active {
    color: #cd9d75;
}

.MuiTab-root:hover,
.MuiTab-root:hover:active,
.MuiTab-root:hover:focus,
.MuiTab-root.Mui-selected {
    color: #21457a !important;
    background-color: #cd9d75;
}

.carousel {
    margin: 15px 20px;
}

.Mui-focused {
    color: rgb(98, 113, 121);
}

.Mui-outlined {
    color: rgb(98, 113, 121) !important;
}

.team-flag {
    width: 20px !important;
    height: 20px !important;
    float: left;
    padding-right: 5px;
}

.flag-visitante {
    float: right;
}

.text-align-left {
    text-align: left;
}

.match-tabs {
    background: #21457a;
}

.match-dropdown {
    background: #21457a;
}

.select {
    color: white !important;
    border-radius: 10px;
    margin: 0 !important;
    padding: 0 !important;
}

.match-title {
    background: #9AC5F4;
}

.normal-round-title {
    color: black !important;
}

.table-footer {
    display: flex;
}

.table-footer .MuiTypography-root {
    margin-right: 5px;
    font-size: 0.8rem;
    color: white !important;
    font-weight: bold;
}

.table-typification {
    margin: 10px;
}

.normal-round-rank {
    background: black;
    font-weight: bold !important;
    font-size: 0.8rem !important;
    color: white !important;
    border-radius: 4px;
    height: 20px;
    width: 20px;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
}

.championship-round-rank-1 {
    background: #053814;
    font-weight: bold !important;
    font-size: 0.8rem !important;
    color: white !important;
    border-radius: 4px;
    height: 20px;
    width: 20px;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
}

.championship-round-rank-2 {
    background: #145a32;
    font-weight: bold !important;
    font-size: 0.8rem !important;
    color: white !important;
    border-radius: 4px;
    height: 20px;
    width: 20px;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
}

.championship-round-rank-3 {
    background: #196f3d;
    font-weight: bold !important;
    font-size: 0.8rem !important;
    color: white !important;
    border-radius: 4px;
    height: 20px;
    width: 20px;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
}

.championship-round-rank-4 {
    background: #1e8449;
    font-weight: bold !important;
    font-size: 0.8rem !important;
    color: white !important;
    border-radius: 4px;
    height: 20px;
    width: 20px;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
}

.championship-round-rank-5 {
    background: #229954;
    font-weight: bold !important;
    font-size: 0.8rem !important;
    color: white !important;
    border-radius: 4px;
    height: 20px;
    width: 20px;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
}

.championship-round-rank-6 {
    background: #27ae60;
    font-weight: bold !important;
    font-size: 0.8rem !important;
    color: white !important;
    border-radius: 4px;
    height: 20px;
    width: 20px;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
}

.relegation-round-rank {
    background: #d32f2f;
    font-weight: bold !important;
    font-size: 0.8rem !important;
    color: white !important;
    border-radius: 4px;
    height: 20px;
    width: 20px;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
}

.poisson-table {
    border-collapse: collapse;
    margin: 0px;
    font-size: 0.8em !important;
    font-family: sans-serif;
    /*min-width: 300px;*/
    max-width: 270px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    word-wrap: break-word;
    table-layout: fixed;
    border: none;
    padding: 2px !important;
}

.enhaced-poisson-toolbar-paper {
    width: 270px;
}

.enhaced-poisson-table {
    border-collapse: collapse;
    margin: 0px;
    font-size: 0.8em !important;
    font-family: sans-serif;
    /*min-width: 300px;*/
    /*max-width: 270px;*/
    width: 270px !important;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    word-wrap: break-word;
    table-layout: fixed;
    border: 5px solid #21457a;
    padding: 2px !important;
}

.enhaced-poisson-toolbar {
    min-height: 0px !important;
    padding: 0px !important;
}

/*Match Tables*/
.match-table {
    border-collapse: collapse;
    margin: 0px 0px 10px;
    font-size: 0.8em !important;
    font-family: sans-serif;
    min-width: 270px;
    max-width: 270px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    word-wrap: break-word;
    table-layout: fixed;
    border: 5px solid #21457a;
    padding: 2px !important;
}

.chance-table {
    border-collapse: collapse;
    margin: 0px 0px 10px;;
    font-size: 0.8em !important;
    font-family: sans-serif;
    /*min-width: 300px;*/
    max-width: 270px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    /*word-wrap: break-word;*/
    table-layout: fixed;
    border: none;
    padding: 2px !important;
}

.minutes-table {
    border-collapse: collapse;
    border-top: 5px solid #21457a;
    border-bottom: 5px solid #21457a;
    border-left: 5px solid #21457a;
    border-right: 5px solid #21457a;
    border-top-left-radius: 5px !important;
    margin: 0px 0px 10px;
    font-size: 0.8em !important;
    font-family: sans-serif;
    /*min-width: 300px;*/
    max-width: 270px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    /*word-wrap: break-word;*/
    table-layout: fixed;
    padding: 0 !important;
}

.chance-table {
    border-collapse: collapse;
    border-top: 5px solid #21457a;
    border-bottom: 5px solid #21457a;
    border-left: 5px solid #21457a;
    border-right: 5px solid #21457a;
    border-top-left-radius: 5px !important;
    margin: 0px 0px 10px;
    font-size: 0.8em !important;
    font-family: sans-serif;
    /*min-width: 300px;*/
    max-width: 270px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    /*word-wrap: break-word;*/
    table-layout: fixed;
    padding: 2px !important;
}

.MuiTableCell-head {
    padding: 0 !important;
}

.MuiTableCell-root {
    font-size: 0.8rem !important;
    padding: 2px !important;
}

.table-body {
    padding: 5px;
}

.enhanced-chance-table {
    border-collapse: collapse;
    margin: 0px 0px 10px;
    font-size: 0.8em !important;
    font-family: sans-serif;
    /*min-width: 300px;*/
    max-width: 270px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    /*word-wrap: break-word;*/
    table-layout: fixed;
    border: 5px solid #21457a;
    padding: 2px !important;
}

.enhanced-matches-table {
    border-collapse: collapse;
    margin: 0px 0px 10px;
    font-size: 0.8em !important;
    font-family: sans-serif;
    /*min-width: 300px;*/
    max-width: 270px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    /*word-wrap: break-word;*/
    table-layout: fixed;
    border: 5px solid #21457a;
    padding: 2px !important;
}

.enhanced-matches-table thead th:nth-child(2) {
    width: 15%;
}

.enhaced-chance-toolbar {
    min-height: 0px !important;
    padding: 0px !important;
}

.minutes-progress-bar {
    height: 16px !important;
    text-align: center;
    border-radius: 4px;
    width: 30px !important;
    /*padding: 2px !important;*/
}

.championship-progress-bar {
    height: 16px !important;
    text-align: center;
    border-radius: 4px;
    width: 100%;
    min-width: 4.0rem;
}

.championship-progress-bar-text {
    color: white !important;
    position: absolute;
    /*right: 60%;*/
    font-weight: bold !important;
    padding-left: 2.0rem;
    /*transform: translate(-10%);*/
}

.cell-green {
    background-color: #98D8AA !important;
}

.cell-yellow {
    background-color: #F7D060 !important;
}

.cell-red {
    background-color: #FF6D60 !important;
}

.market-team-statistics-table {
    margin: 0 0 4px 0 !important;
    width: 640px;
}

.users .MuiDataGrid-row.Mui-selected {
    background-color: #F7D060 !important;
}

.users .MuiDataGrid-columnHeader,
.users .MuiDataGrid-footerContainer,
.championship .MuiDataGrid-columnHeader,
.championship .MuiDataGrid-footerContainer {
    background: #21457a;
}

.MuiDataGrid-root {
    font-size: 0.8em !important;
}

.MuiBox-root {
    margin-right: 0px;
}

.championship-table-cell {
    white-space: nowrap;
    font-weight: bold !important;
    border-color: white !important;
    border-width: 0 2px !important;
    border-style: solid;
    padding: 0 4px !important;
    margin-right: 0 !important;
    /*background: linear-gradient(90deg, #98D8AA 0%, #F7D060 70%, #FF6D60 100%) !important;*/
}

.minutes-table-cell {
    white-space: nowrap;
    padding: 2px !important;
    text-align: center !important;
    /*font-weight: bold !important;*/
    /*background: linear-gradient(90deg, #98D8AA 0%, #F7D060 70%, #FF6D60 100%) !important;*/
}

.table-title {
    border-top: 5px solid #21457a;
    border-left: 5px solid #21457a;
    border-right: 5px solid #21457a;
    background: #21457a;
    color: white !important;
}

.total-jogos {
    border-top: 5px solid #21457a;
}

.MuiTableSortLabel-iconDirectionDesc {
    display: none !important;
}

.MuiTableSortLabel-iconDirectionAsc {
    display: none !important;
}

.table-header {
    font-size: 0.8rem !important;
    font-weight: bold !important;
    margin-left: 2px !important;
    margin-right: 2px !important;
    color: white !important;
}

.odd-input,
.odd-input .MuiFormLabel-root,
.odd-input .MuiInputBase-input {
    background-color: lightseagreen !important;
    color: white !important;
}

.order-element {
    margin: 0px !important;
}

.enhaced-table-title {
    font-size: 0.8rem !important;
    padding: 0px !important;
    color: white !important;
}

.col:nth-child(even) {
    color: #21457a !important;
}

.col:nth-child(odd) {
    color: #122544 !important;
}

.line-color:nth-child(even) {
    border: 1px solid #21457a;
    border-top: none;
    background: lightsteelblue;
}

.line-color:nth-child(odd) {
    border: 1px solid #21457a;
    border-bottom: none;
    background: lightgray;
}

.line-color[highlight="true"] {
    background: #F7D060;
}

.table-body[data-separator="top-block"] {
    /*border-bottom: 1px solid black !important;*/
    border-top: 5px solid #21457a !important;
    padding-top: 5px;
}

/*tr:nth-child(even) {background: #CCC}*/
/*tr:nth-child(odd) {background: #FFF}*/

.page-menu-item[hide="true"] {
    display: none !important;
}

.page-menu-item[hide="false"] {

}

.overUnder {
    white-space: nowrap;
    font-weight: bold !important;
    border-color: white !important;
    border-width: 0px 0px !important;
    border-style: solid;
    padding: 4px !important;
    margin-right: 0px !important;
    color: white;
    background-color: #21457a;
}

.MuiDataGrid-row:nth-child(even) {
    background-color: lightsteelblue;
    color: black !important;
}

.MuiDataGrid-row:nth-child(odd) {
    background-color: lightgray;
    color: black !important;
}

.MuiDataGrid-row:hover {
    background-color: #F7D060 !important;
}

.MuiDataGrid-cell--editing input {
    color: white !important;
    font-weight: bold !important;
}

.MuiDataGrid-cell--editable {
    background-color: lightseagreen !important;
    color: white !important;
}

.overUnder .MuiDataGrid-row.Mui-selected {
    background-color: #F7D060 !important;
}

.championship {
    width: 100%;
}

.championship-dropdown {
    background: #21457a;
}

.championship .MuiDataGrid-row.Mui-selected {
    background-color: #F7D060 !important;
}

.termo {
    color: #21457a !important;
}

.termo:hover {
    color: red !important;
}

.copyright {
    background-color: black !important;
}

.match-principal {
    /*border-right: 5px solid #21457a;*/
    /*border-left: 5px solid #21457a;*/
    background: #FFEEBB;
}

.match-visitor {
    /*border-right: 5px solid #21457a;*/
    /*border-left: 5px solid #21457a;*/
    background: #9AC5F4;
}

.match-table-row {
    border: 1px solid #21457a;
}

.chance-status[data-status="visitor-color"] {
    background: #9AC5F4;
}

.chance-status[data-status="principal-color"] {
    background: #FFEEBB;
}

.match-status[data-status="good"] {
    background: #98D8AA;
}

.match-status[data-status="neutral"] {
    background: #F7D060;
}

.match-status[data-status="bad"] {
    background: #FF6D60;
}

.match-status[data-status="blue"] {
    background: #87a7e7;
}

.match-status[data-status="purple"] {
    background: #E1AEFF;
}

.match-status[data-status="grey"] {
    background: #bbc0dc;
}

.poisson-status[data-status="blue"] {
    background: #9AC5F4;
}

.poisson-status[data-status="yellow"] {
    background: #FFEEBB;
}

.poisson-status[data-status="level10"] {
    background: #98D8AA;
}

.poisson-status[data-status="level9"] {
    background: #A3CCA2;
}

.poisson-status[data-status="level8"] {
    background: #AFC09A;
}

.poisson-status[data-status="level7"] {
    background: #BAB491;
}

.poisson-status[data-status="level6"] {
    background: #C6A889;
}

.poisson-status[data-status="level5"] {
    background: #D19D81;
}

.poisson-status[data-status="level4"] {
    background: #DD9179;
}

.poisson-status[data-status="level3"] {
    background: #E88570;
}

.poisson-status[data-status="level2"] {
    background: #F47968;
}

.poisson-status[data-status="level1"] {
    background: #FF6D60;
}

.chance-status[data-status="level10"] {
    background: #98D8AA;
}

.chance-status[data-status="level9"] {
    background: #A3CCA2;
}

.chance-status[data-status="level8"] {
    background: #AFC09A;
}

.chance-status[data-status="level7"] {
    background: #BAB491;
}

.chance-status[data-status="level6"] {
    background: #C6A889;
}

.chance-status[data-status="level5"] {
    background: #D19D81;
}

.chance-status[data-status="level4"] {
    background: #DD9179;
}

.chance-status[data-status="level3"] {
    background: #E88570;
}

.chance-status[data-status="level2"] {
    background: #F47968;
}

.chance-status[data-status="level1"] {
    background: #FF6D60;
}

.term {
    padding-top: 20px;
    padding-bottom: 20px;
}

.chapter {
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
}

.paragraph {
    text-align: justify;
    padding-bottom: 10px;
}

.sign {
    font-style: italic;
    text-align: left;
}
